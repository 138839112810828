<template>
  <div class="sprints">
    <div class="padding_top"></div>

    <b-container>
      <b-row>
        <b-col cols="12">
          <div v-swiper:globalSwiper="swiperOption">
            <div class="swiper-pagination"></div>

            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <global-goal :statuses="statuses" :spheres="spheres" />
              </div>

              <div class="swiper-slide">
                <b-row>
                  <b-col cols="12">
                    <h2 class="sprint_slider__title">Спринты</h2>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <div class="sprint_card" v-b-modal.sprint>
                      <h2 class="sprint_card__title">+</h2>
                    </div>

                    <div
                      v-for="(sprint, index) in sprints"
                      :key="index"
                      class="sprint_card"
                      @click="show(sprint.id)"
                    >
                      <h2 class="sprint_card__title">{{ sprint.title }}</h2>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="sprint"
      size="lg"
      hide-footer
      header-bg-variant="dark"
      body-bg-variant="dark"
      centered
    >
      <b-form @submit="addSprint">
        <!-- Info -->
        <b-row>
          <b-col cols="12">
            <label>Название спринта</label>
            <b-form-input
              v-model="sprintEditor.title"
              class="global_goal__title"
              placeholder="Описание"
            ></b-form-input>
          </b-col>
        </b-row>

        <!-- Save button -->
        <b-row v-if="sprintEditor.id">
          <b-button @click="saveSprint()" :disabled="loading" block
            >Сохранить</b-button
          >
        </b-row>

        <!-- Save button -->
        <b-row v-else>
          <b-button type="submit" :disabled="loading" block>Создать</b-button>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import GlobalGoal from "../../components/sprint/GlobalGoal.vue";
import { mapActions, mapState } from "vuex";
import axios from "axios";

export default {
  name: "Sprints",
  components: { GlobalGoal },
  data() {
    return {
      statuses: [
        { value: 1, text: "в работе" },
        { value: 2, text: "выполнена" },
        { value: 3, text: "просрочена" },
      ],
      spheres: [],
      sprintEditor: {
        title: null,
        description: null,
      },

      swiperOption: {
        cssMode: true,
        mousewheel: {
          forceToAxis: true,
        },
        // Default parameters
        slidesPerView: 1,
        spaceBetween: 15,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          1024: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
  computed: {
    ...mapState({
      sprints: (state) => state.sprint.sprints,
      loading: (state) => state.loading,
    }),
  },
  methods: {
    ...mapActions(["getGlobalGoals", "getSprints"]),
    show(id) {
      this.$router.push({ path: `sprint/${id}` });
    },
    async addSprint(ev) {
      ev.preventDefault();

      try {
        this.$store.commit("SET_LOADING", true);

        await axios.put("sprint", this.sprintEditor);
        await this.getSprints();

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    },
  },
  async mounted() {
    if (!localStorage.getItem("token")) {
      return;
    }

    try {
      this.$store.commit("SET_LOADING", true);

      await this.$store.dispatch("getUser");

      let lifeSpheres = await axios.get("life_spheres").then((res) => {
        return res.data;
      });

      this.spheres = lifeSpheres;
      await this.getGlobalGoals();
      await this.getSprints();

      this.$store.commit("SET_LOADING", false);
    } catch (e) {
      this.$store.commit("SET_LOADING", false);
      this.$bvToast.toast(e);
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
.sprint_card {
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 15px;
  cursor: pointer;
}
</style>
